<template>
  <div class="page_bg">
    <div class="ship_bg">
      <div class="ship_contant ">
        <div class="ship_contant_no">货单号：{{ shopinglistId }}</div>
        <div class="ship_contant_pro" style="margin-bottom:3rem">
          <!-- <MyscrollList ref="myList01" :loading="listLoading" :list="shiplistInfo.proucts" :line-type="1" @myload="proload"> -->
          <!-- <template #default="slotProps"> -->
          <div v-for="(item,index) in shiplistInfo.proucts" :key="index" class="company_pro_list">
            <div class="flex-r-s-c">
              <proCard08 :info="item" />
            </div>
          </div>
          <!-- </template>
          </MyscrollList> -->
        </div>
      </div>
      <!-- 底部提交 -->
      <div class="ship_contant_sub flex-r-sa-c">
        <van-submit-bar :price="price" button-text="货单下单" button-color="#0767AB"
                        @submit="subconfirmCase"
        />
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import proCard08 from '@/components/pro_card_08'
// import MyscrollList from '@/components/MyscrollList'
import { getShoppinglistGoodsDetail } from '@/api/order'
// import { getShoppinglistGoodsDetail, shopping_getShoppinglist } from '@/api/order'
export default {
  components: { proCard08 },
  // components: { MyscrollList, proCard06 },
  data() {
    return {
      // 货单信息
      shiplistInfo: {},
      price: null,
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 20, shopinglistId: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.shopinglistId = this.$route.query.id
    this.proSearch.shopinglistId = this.$route.query.id
    this.getDetail()
    // this.getProList()
  },
  methods: {
    // 获取详情
    getDetail() {
      getShoppinglistGoodsDetail(this.shopinglistId).then(res => {
        if (!res.data.isInvalid) {
          this.shiplistInfo = res.data
          this.price = parseInt(this.shiplistInfo.total.replace('.', ''))
        } else {
          this.$router.push('/order/shiplistNotWork?type=1')
        }
      })
    },
    // // 获取商品
    // getProList() {
    //   this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
    //   shopping_getShoppinglist(this.proSearch).then(res => {
    //     this.$toast.clear()
    //     this.$refs.myList01.load_loading(false)
    //     this.proList = this.proList.concat(res.data.list)
    //     if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
    //       if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
    //     }
    //   })
    // },
    // proload() {
    //   this.$refs.myList01.load_loading(true)
    //   this.$utils.debounce(() => {
    //     this.proSearch.pageNum = this.proSearch.pageNum + 1
    //     this.getProList()
    //     // 加载状态结束
    //   }, 50)()
    // },
    // 提交
    subconfirmCase() {
      this.$router.push(`/order/subOrder?type=3&shopinglistId=${this.shopinglistId}`)
    }
  }
}
</script>

<style>
</style>
